import React from 'react';
import logo from './logo.png';
import './App.css';
import { Swap } from './components/Swap';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';





function App() {
  // const [currentAccount, setCurrentAccount] = useState(null);
  // const checkWalletIsConnected = async () => {
  //   const { ethereum } = window;
  //   if (!ethereum) {
  //     console.log("Make sure you have Metamask installed!");
  //     return;
  //   } else {
  //     console.log("Wallet exists! We're ready to go!")
  //   }

  //   const accounts = await ethereum.request({ method: 'eth_accounts' });
  //   if (accounts.length !== 0) {
  //     const account = accounts[0];
  //     console.log("Found an authorized account: ", account);
  //     setCurrentAccount(account);
  //   } else {
  //     console.log("No authorized account found");
  //   }
  // }

  // const connectWalletHandler = async () => {
  //   const { ethereum } = window;

  //   if (!ethereum) {
  //     alert("Please install Metamask!");
  //   }

  //   try {
  //     const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
  //     console.log("Found an account! Address: ", accounts[0]);
  //     setCurrentAccount(accounts[0]);
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }

  // const connectWalletButton = () => {
  //   return (
  //     <button onClick={connectWalletHandler} className='cta-button connect-wallet-button'>
  //       Connect Wallet
  //     </button>
  //   )
  // }


  return (
    <>
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p></p>
          <Swap />
          {/* {connectWalletButton()} */}
        </header>
      </div>
    </>
  );
}

export default App;
