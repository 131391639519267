import React from "react";
import { darkTheme, Theme, SwapWidget, JsonRpcProvider } from '@uniswap/widgets'
import "@uniswap/widgets/fonts.css";
import { Provider } from "@web3-react/types";
//const TOKEN_LIST = 'https://bafybeigfrqindue4ke4zvbtdt4dp3v3o5mqgguud2mwmvkzk7ct73dcg2q.ipfs.dweb.link/'
const SUPERPEPE = '0x332e01e82Be1b1D3d4B86c35628f6f52D944777d'

const TOKEN_LIST = [
    {
        "name": "Wrapped Ether",
        "address": "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
        "symbol": "WETH",
        "decimals": 18,
        "chainId": 1,
        "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
    },
    {
        "chainId": 1,
        "address": "0x332e01e82Be1b1D3d4B86c35628f6f52D944777d",
        "name": "BTC FUND AI",
        "symbol": "BFAI",
        "decimals": 18,
        "logoURI": "https://raw.githubusercontent.com/BTCFundAI/assets/main/logo200.png"
    }
]

const enableColorExtractionTheme: Theme = {
    ...darkTheme,
    // primary: '#000',
    // secondary: '#666',
    // interactive: '#0089EC',
    // container: '#FFF',
    // module: '#E7E7E7',
    // accent: '#3D3B31',
    // outline: '#343D3A',
    // dialog: '#FFF',
    // fontFamily: 'Verdana',
    // borderRadius: 0.8,
    //  tokenColorExtraction: false, // Enable color extraction of the output token
}

const Swap = () => {
    return (
        <div className="Uniswap">
            <SwapWidget
                defaultInputTokenAddress="NATIVE"
                tokenList={TOKEN_LIST}
                // provider={value}
                defaultInputAmount="0.1"
                theme={enableColorExtractionTheme}
                defaultOutputTokenAddress={SUPERPEPE}
                width="100%"
                permit2={true}
                brandedFooter={false}
            />
        </div>
    );
};

export default Swap;
